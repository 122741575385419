import React, {useCallback, useContext, useEffect, useState} from "react";
import {Form} from "semantic-ui-react";
import {AlertContext} from "contexts/AlertContext";
import {TermCategory} from "services/LegalApi/TermCategory";
import {ButtonsWithDeleteConfirm} from "components/Form/ButtonsWithDeleteConfirm";

const isSame = (category, categoryFormData) => {
    const newCategory = Object.fromEntries(categoryFormData);

    return category.name === newCategory.name &&
        category.ordering === newCategory.ordering;
}

export const TermCategoryForm = ({onSubmit, category}) => {
    const {handleErrorAlert} = useContext(AlertContext);

    const [{name, ordering}, setState] = useState({name: "", ordering: 0});

    const handleChange = useCallback((e, {name, value}) => {
        setState(prevState => ({...prevState, [name]: value}));
    }, []);
    const handleSubmit = useCallback(({target}) => {
        const formData = new FormData(target);

        if (category) {
            if (!isSame(category, formData)) TermCategory.update(category.id, formData)
                .then(_ => onSubmit())
                .catch(error => handleErrorAlert(error, "Can't update"));
        } else TermCategory.create(formData)
            .then(_ => onSubmit())
            .catch(error => handleErrorAlert(error, "Can't add"));
    }, [category, handleErrorAlert, onSubmit]);
    const handleDelete = useCallback(() => {
        TermCategory.remove(category.id)
            .then(_ => onSubmit())
            .catch(error => handleErrorAlert(error, "Can't delete"));
    }, [category, handleErrorAlert, onSubmit]);

    useEffect(() => setState({name: category?.name || "", ordering: category?.ordering || 0}), [category]);

    return <Form onSubmit={handleSubmit}>
        <Form.Input required label="#" name="ordering" value={ordering} onChange={handleChange} type="number"/>

        <Form.Input required label="Name" name="name" value={name} onChange={handleChange}/>

        <ButtonsWithDeleteConfirm isAdd={category == null} onDelete={handleDelete} header="This can't be undone"
                                  content="Related terms and document values will be deleted. Are you sure?"/>
    </Form>;
}
