import React from "react";
import {Grid, Header, Image, Message, Segment} from "semantic-ui-react";
import {SignInButton} from "components/Button/SignInButton";

export const SignInPage = () => (
    <Grid centered textAlign="center" verticalAlign="middle" style={{height: "100%"}}>
        <Grid.Column largeScreen="4" computer="6" tablet="8" mobile="15">
            <Header as="h2" textAlign="center" content="Welcome back" subheader="Sign in to your account to continue"/>
            <Segment raised>
                <Image centered src="surmount-ventures-logo-black-rgb.svg" size="medium"
                       style={{marginBottom: "2rem"}}/>
                <SignInButton/>
                <Message info content="Use your SV Microsoft account"/>
            </Segment>
        </Grid.Column>
    </Grid>
)