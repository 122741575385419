import React from "react";
import "App.css";
import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "authConfig";
import {BrowserRouter} from "react-router-dom";
import {AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate} from "@azure/msal-react";
import {AuthenticatedContainer} from "containers/AuthenticatedContainer";
import {UnauthenticatedContainer} from "containers/UnauthenticatedContainer";
import {AlertProvider} from "contexts/AlertContext";

export const msalInstance = new PublicClientApplication(msalConfig);

export const App = () =>
    <AlertProvider>
        <MsalProvider instance={msalInstance}>
            <BrowserRouter>
                <AuthenticatedTemplate>
                    <AuthenticatedContainer/>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <UnauthenticatedContainer/>
                </UnauthenticatedTemplate>
            </BrowserRouter>
        </MsalProvider>
    </AlertProvider>
