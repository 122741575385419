import React from "react";
import {AlertContext} from "contexts/AlertContext";
import {Message} from "semantic-ui-react";

export const AlertContainer = () => {
    return (
        <AlertContext.Consumer>
            {({alerts, deleteAlert}) => alerts.map(alert =>
                <Message key={alert.id} style={{marginTop: "1rem"}}
                         info={alert.alertClass === "info"} warning={alert.alertClass === "warning"}
                         error={alert.alertClass === "error"} success={alert.alertClass === "success"}
                         header={alert.alertHeader} content={alert.alertText} onDismiss={() => deleteAlert(alert.id)}/>
            )}
        </AlertContext.Consumer>
    );
}