import {createContext, useCallback, useState} from "react";

export const AlertContext = createContext(null);
AlertContext.displayName = "AlertContext";

let _ID = 0;

export const AlertProvider = ({children}) => {
    const [alerts, setAlerts] = useState([]);
    const addAlert = useCallback((alertClass, alertHeader, alertText) =>
        setAlerts(alerts => [...alerts, {id: _ID++, alertClass, alertHeader, alertText}]), []);
    const deleteAlert = useCallback(id => setAlerts(alerts => alerts.filter(m => m.id !== id)), []);
    const handleErrorAlert = useCallback((error, alertHeader) => {
        console.log(error.toString());
        if (error.hasOwnProperty("response"))
            console.log(error.response);

        if ((error.response?.data || "") !== "" && error.response?.data?.hasOwnProperty("message"))
            addAlert("error", alertHeader, error.response.data.message);
        else if ((error.response?.data || "") !== "")
            addAlert("error", alertHeader, error.response.data);
        else addAlert("error", alertHeader, error.toString());
    }, [addAlert]);

    return (
        <AlertContext.Provider value={{alerts, addAlert, deleteAlert, handleErrorAlert}}>
            {children}
        </AlertContext.Provider>
    );
}