import React, {useCallback, useContext, useEffect, useState} from "react";
import {Form} from "semantic-ui-react";
import {AlertContext} from "contexts/AlertContext";
import {Term} from "services/LegalApi/Term";
import {ButtonsWithDeleteConfirm} from "components/Form/ButtonsWithDeleteConfirm";

const isSame = (term, termFormData) => {
    const newTerm = Object.fromEntries(termFormData);

    return term.name === newTerm.name &&
        term.categoryId === newTerm.categoryId &&
        term.ordering === newTerm.ordering;
}

export const TermForm = ({onSubmit, term, categoryId}) => {
    const {handleErrorAlert} = useContext(AlertContext);

    const [{name, ordering}, setState] = useState({name: "", ordering: 0});

    const handleChange = useCallback((e, {name, value}) => {
        setState(prevState => ({...prevState, [name]: value}));
    }, []);
    const handleSubmit = useCallback(({target}) => {
        const formData = new FormData(target);
        formData.set("categoryId", categoryId);

        if (term) {
            if (!isSame(term, formData)) Term.update(term.id, formData)
                .then(_ => onSubmit())
                .catch(error => handleErrorAlert(error, "Can't update"));
        } else Term.create(formData)
            .then(_ => onSubmit())
            .catch(error => handleErrorAlert(error, "Can't add"));
    }, [categoryId, handleErrorAlert, onSubmit, term]);
    const handleDelete = useCallback(() => {
        Term.remove(term.id)
            .then(_ => onSubmit())
            .catch(error => handleErrorAlert(error, "Can't delete"));
    }, [handleErrorAlert, onSubmit, term]);

    useEffect(() => setState({name: term?.name || "", ordering: term?.ordering || 0}), [term]);

    return <Form onSubmit={handleSubmit}>
        <Form.Input required label="#" name="ordering" value={ordering} onChange={handleChange} type="number"/>

        <Form.Input required label="Name" name="name" value={name} onChange={handleChange}/>

        <ButtonsWithDeleteConfirm isAdd={term == null} onDelete={handleDelete} header="This can't be undone"
                                  content="Related document values will be deleted. Are you sure?"/>
    </Form>;
}