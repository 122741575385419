import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Container, Grid} from "semantic-ui-react";
import {ConfigurationMenu} from "components/Menu/ConfigurationMenu";
import {AlertContext} from "contexts/AlertContext";
import {TermCategory} from "services/LegalApi/TermCategory";
import {FormContainer} from "containers/FormContainer";
import {TermCategoryForm} from "components/Form/TermCategoryForm";
import {TermForm} from "components/Form/TermForm";
import {Transaction} from "services/LegalApi/Transaction";
import {TransactionForm} from "components/Form/TransactionForm";
import {TransactionDocumentForm} from "components/Form/TransactionDocumentForm";
import {find} from "lodash";

export const ConfigurationPage = () => {
    const [activeItem, setActiveItem] = useState("term category");

    const handleItemClick = useCallback((e, {name}) => setActiveItem(name), []);

    return <Container>
        <Grid divided relaxed stackable>
            <Grid.Row>
                <Grid.Column computer="3" tablet="4" mobile="16">
                    <ConfigurationMenu activeItem={activeItem} handleItemClick={handleItemClick}
                                       headerText="Configuration"
                                       items={["term category", "transaction"]}/>
                </Grid.Column>

                <Grid.Column computer="13" tablet="12" mobile="16">
                    {activeItem === "term category" ? <TermCategoryConfigurationContainer/> :
                        activeItem === "transaction" ? <TransactionConfigurationContainer/> : ""}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Container>
}

const categorySearchAttributes = ["name"];
const termSearchAttributes = ["name"];
const TermCategoryConfigurationContainer = () => {
    const {handleErrorAlert} = useContext(AlertContext);

    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryId] = useState(null);
    const [termId, setTermId] = useState(null);

    const loadData = useCallback(() => {
        TermCategory.indexIncludeTerms()
            .then(response => setCategories(response.data.map(c => ({
                    ...c,
                    title: `#${c.ordering} ${c.name}`,
                    terms: c.terms.map(t => ({
                        ...t,
                        title: `#${t.ordering} ${t.name}`
                    }))
                })))
            ).catch(error => handleErrorAlert(error, "Can't get Term Categories and Terms"));
    }, [handleErrorAlert]);

    const category = useMemo(() => find(categories, ["id", categoryId]), [categories, categoryId]);
    const term = useMemo(() => find(category?.terms, ["id", termId]), [category, termId]);
    const cHeader = useMemo(() => `Term Category ${category ? "Edit" : "Create"}`, [category]);
    const tHeader = useMemo(() => `Term ${term ? "Edit" : "Create"}`, [term]);

    useEffect(() => loadData(), [loadData]);
    useEffect(() => setTermId(null), [categoryId]);

    return <Grid>
        <Grid.Column computer="8" tablet="8" mobile="16">
            <FormContainer data={categories} header={cHeader} searchAttr={categorySearchAttributes}
                           form={<TermCategoryForm onSubmit={loadData} category={category}/>}
                           menuKey="id" menuName="title" activeKey={categoryId}
                           handleClick={(category) => setCategoryId(category.id)}/>
        </Grid.Column>

        {category && <Grid.Column computer="8" tablet="8" mobile="16">
            <FormContainer data={category.terms} header={tHeader} searchAttr={termSearchAttributes}
                           form={<TermForm onSubmit={loadData} term={term} categoryId={categoryId}/>}
                           menuKey="id" menuName="title" activeKey={termId} handleClick={(term) => setTermId(term.id)}/>
        </Grid.Column>}
    </Grid>
}

const transactionSearchAttributes = ["name"];
const documentSearchAttributes = ["name"];
const TransactionConfigurationContainer = () => {
    const {handleErrorAlert} = useContext(AlertContext);

    const [transactions, setTransactions] = useState([]);
    const [transactionId, setTransactionId] = useState(null);
    const [documentId, setDocumentId] = useState(null);

    const loadData = useCallback(() => {
        Transaction.indexIncludeDocuments()
            .then(response => setTransactions(response.data))
            .catch(error => handleErrorAlert(error, "Can't get Transactions and Transaction Documents"));
    }, [handleErrorAlert]);

    const transaction = useMemo(() => find(transactions, ["id", transactionId]), [transactions, transactionId]);
    const document = useMemo(() => find(transaction?.documents, ["id", documentId]), [transaction, documentId]);
    const tHeader = useMemo(() => `Transaction ${transaction ? "Edit" : "Create"}`, [transaction]);
    const dHeader = useMemo(() => `Transaction Document ${document ? "Edit" : "Create"}`, [document]);

    useEffect(() => loadData(), [loadData]);
    useEffect(() => setDocumentId(null), [transactionId]);

    return <Grid>
        <Grid.Column computer="8" tablet="8" mobile="16">
            <FormContainer data={transactions} header={tHeader} searchAttr={transactionSearchAttributes}
                           form={<TransactionForm onSubmit={loadData} transaction={transaction}/>}
                           menuKey="id" menuName="name" activeKey={transactionId}
                           handleClick={(transaction) => setTransactionId(transaction.id)}/>
        </Grid.Column>

        {transaction && <Grid.Column computer="8" tablet="8" mobile="16">
            <FormContainer data={transaction.documents} header={dHeader} searchAttr={documentSearchAttributes}
                           form={<TransactionDocumentForm onSubmit={loadData} document={document}
                                                          transactionId={transactionId}/>}
                           menuKey="id" menuName="name" activeKey={documentId}
                           handleClick={(document) => setDocumentId(document.id)}/>
        </Grid.Column>}
    </Grid>
}