import {destroy, get, post, put} from "services/LegalApi/base";

export const TransactionDocument = {
    index: () => get("/transaction_document/"),
    indexIncludeValues: () => get("/transaction_document/", {params: {include: "values"}}),
    single: (id) => get(`/transaction_document/${id}`),
    singleIncludeValues: (id) => get(`/transaction_document/${id}`, {params: {include: "values"}}),
    create: (params) => post("/transaction_document/", params),
    update: (id, params) => put(`/transaction_document/${id}`, params),
    remove: (id) => destroy(`/transaction_document/${id}`),
};