import React, {useCallback, useState} from "react";
import {Button, Confirm, Form} from "semantic-ui-react";

export const ButtonsWithDeleteConfirm = ({isAdd, onDelete, header, content}) => {
    const [showConfirm, setShowConfirm] = useState(false);

    const handleConfirm = useCallback(() => {
        setShowConfirm(false);
        onDelete();
    }, [onDelete]);

    return isAdd ? <Form.Button positive content="Add"/> :
        <>
            <Button primary content="Update"/>
            <Button floated="right" negative content="Delete" type="button" onClick={() => setShowConfirm(true)}/>
            <Confirm open={showConfirm} onCancel={() => setShowConfirm(false)} onConfirm={handleConfirm}
                     header={header} content={content} cancelButton="Never mind" confirmButton="Let's do it"/>
        </>
}