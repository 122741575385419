import {Menu} from "semantic-ui-react";
import React from "react";

export const ConfigurationMenu = ({activeItem, handleItemClick, headerText, items}) => {
    return (
        <Menu fluid vertical>
            {headerText && <Menu.Item header as="h5" name={headerText}/>}

            {items.map(i => <Menu.Item key={i} name={i} active={activeItem === i} onClick={handleItemClick}/>)}
        </Menu>
    );
}