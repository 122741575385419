import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {AlertContext} from "contexts/AlertContext";
import {TermCategory} from "services/LegalApi/TermCategory";
import {Container, Form, Grid, Header, Modal, Segment} from "semantic-ui-react";
import {Transaction} from "services/LegalApi/Transaction";
import {TransactionDocument} from "services/LegalApi/TransactionDocument";
import {EditDocumentValuesTable} from "components/Table/EditDocumentValuesTable";
import {DocumentValueForm} from "components/Form/DocumentValueForm";
import {find} from "lodash";

export const EditDataPage = () => {
    const {handleErrorAlert} = useContext(AlertContext);

    const [categories, setCategories] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [transaction, setTransaction] = useState(null);
    const [document, setDocument] = useState(null);

    const loadDocument = useCallback((documentId) => {
        if (documentId == null) setDocument(null);
        else TransactionDocument.singleIncludeValues(documentId)
            .then(response => setDocument(response.data))
            .catch(error => handleErrorAlert(error, "Can't get Transaction Document"));
    }, [handleErrorAlert]);

    useEffect(() => {
        TermCategory.indexIncludeTerms()
            .then(response => setCategories(response.data))
            .catch(error => handleErrorAlert(error, "Can't get Term Categories and Terms"));
        Transaction.indexIncludeDocuments()
            .then(response => setTransactions(response.data))
            .catch(error => handleErrorAlert(error, "Can't get Transactions and Transaction Documents"));
    }, [handleErrorAlert]);

    return <Container>
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <DocumentSelectorSegment transactions={transactions} documents={transaction?.documents}
                                             setTransaction={setTransaction} loadDocument={loadDocument}/>
                </Grid.Column>
            </Grid.Row>

            {document && categories.map(c => <Grid.Row key={c.id}>
                <Grid.Column>
                    <EditDocumentValuesSegment category={c} document={document} loadDocument={loadDocument}/>
                </Grid.Column>
            </Grid.Row>)}
        </Grid>
    </Container>
}

const DocumentSelectorSegment = ({transactions, documents, setTransaction, loadDocument}) => {
    const [{transactionId, documentId}, setState] = useState({transactionId: null, documentId: null});

    const tOptions = useMemo(() => transactions.map(t => ({key: t.id, text: t.name, value: t.id})), [transactions]);
    const dOptions = useMemo(() => documents?.map(d => ({key: d.id, text: d.name, value: d.id})), [documents]);
    const transaction = useMemo(() => find(transactions, ["id", transactionId]), [transactionId, transactions]);

    const handleChange = useCallback((e, {name, value}) => {
        setState(prevState => ({...prevState, [name]: value}));
    }, []);

    useEffect(() => handleChange(null, {name: "documentId", value: null}), [handleChange, transactionId]);
    useEffect(() => setTransaction(transaction), [setTransaction, transaction, transactions]);
    useEffect(() => loadDocument(documentId), [loadDocument, documentId]);

    return <Segment>
        <Header as="h3" content="Edit Data"/>

        <Form>
            <Form.Group widths="equal">
                <Form.Dropdown label="Transaction" search options={tOptions} selection selectOnNavigation={false}
                               deburr name="transactionId" onChange={handleChange} value={transactionId}/>
                <Form.Dropdown label="Document" search options={dOptions || []} selection selectOnNavigation={false}
                               deburr name="documentId" onChange={handleChange} value={documentId}/>
            </Form.Group>
        </Form>
    </Segment>
}

const EditDocumentValuesSegment = ({category, document, loadDocument}) => {
    const [{open, term, value}, setState] = useState({open: false, term: null, value: null});

    const setOpen = useCallback((open) => setState(prevState => ({...prevState, open})), []);
    const handleSubmit = useCallback(() => {
        loadDocument(document.id);
        setOpen(false);
    }, [setOpen, document, loadDocument]);

    return <Segment>
        <EditDocumentValuesTable category={category} document={document}
                                 handleRowClick={(term, value) => setState({open: true, term, value})}/>

        {open && <Modal open={open} onClose={() => setOpen(false)} closeIcon
                        closeOnDimmerClick={false} closeOnEscape={false}>
            <Modal.Header>{term.name}</Modal.Header>

            <Modal.Content>
                <DocumentValueForm onSubmit={handleSubmit} value={value}/>
            </Modal.Content>
        </Modal>}
    </Segment>
}