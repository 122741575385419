import React, {useCallback} from "react";
import {debounce, escapeRegExp} from "lodash";
import {Input} from "semantic-ui-react";

export const DebouncedSearch = ({data, setFilteredData, attributes, duration}) => {
    const handleSearchChange = useCallback((e, {value}) => {
        if (data.length === 0) return;
        if (value.length < 1) return setFilteredData(data);

        const re = new RegExp(escapeRegExp(value), "i");
        const isMatch = result => attributes.some(a => re.test(result[a]));

        setFilteredData(data.filter(isMatch));
    }, [attributes, data, setFilteredData]);

    return (
        <Input icon="search" onChange={debounce(handleSearchChange, duration, {leading: true})}/>
    );
}