import React, {useCallback, useContext, useEffect, useState} from "react";
import {AlertContext} from "contexts/AlertContext";
import {Form} from "semantic-ui-react";
import {ButtonsWithDeleteConfirm} from "components/Form/ButtonsWithDeleteConfirm";
import {TransactionDocument} from "services/LegalApi/TransactionDocument";

const isSame = (document, documentFormData) => {
    const newDocument = Object.fromEntries(documentFormData);

    return document.name === newDocument.name &&
        document.transactionId === newDocument.transactionId;
}

export const TransactionDocumentForm = ({onSubmit, document, transactionId}) => {
    const {handleErrorAlert} = useContext(AlertContext);

    const [{name}, setState] = useState({name: ""});

    const handleChange = useCallback((e, {name, value}) => {
        setState(prevState => ({...prevState, [name]: value}));
    }, []);
    const handleSubmit = useCallback(({target}) => {
        const formData = new FormData(target);
        formData.set("transactionId", transactionId);

        if (document) {
            if (!isSame(document, formData)) TransactionDocument.update(document.id, formData)
                .then(_ => onSubmit())
                .catch(error => handleErrorAlert(error, "Can't update"));
        } else TransactionDocument.create(formData)
            .then(_ => onSubmit())
            .catch(error => handleErrorAlert(error, "Can't add"));
    }, [document, handleErrorAlert, onSubmit, transactionId]);
    const handleDelete = useCallback(() => {
        TransactionDocument.remove(document.id)
            .then(_ => onSubmit())
            .catch(error => handleErrorAlert(error, "Can't delete"));
    }, [document, handleErrorAlert, onSubmit]);

    useEffect(() => setState({name: document?.name || ""}), [document]);

    return <Form onSubmit={handleSubmit}>
        <Form.Input required label="Name" name="name" value={name} onChange={handleChange}/>

        <ButtonsWithDeleteConfirm isAdd={document == null} onDelete={handleDelete} header="This can't be undone"
                                  content="Related values will be deleted. Are you sure?"/>
    </Form>;
}