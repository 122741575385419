import React from "react";
import {useMsal} from "@azure/msal-react";
import {Dropdown} from "semantic-ui-react";

function handleLogout(instance) {
    instance.logoutRedirect().catch(e => console.error(e));
}

export const SignOutDropdownItem = () => {
    const {instance} = useMsal();

    return (
        <Dropdown.Item text="Sign out" onClick={() => handleLogout(instance)}/>
    )
}