import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Form, Grid, Header, Segment} from "semantic-ui-react";
import {AlertContext} from "contexts/AlertContext";
import {Transaction} from "services/LegalApi/Transaction";
import {TermCategory} from "services/LegalApi/TermCategory";
import {ViewDocumentValuesTable} from "components/Table/ViewDocumentValuesTable";
import {find, groupBy, size} from "lodash";
import {TransactionDocument} from "services/LegalApi/TransactionDocument";

export const HomePage = () => {
    const {handleErrorAlert} = useContext(AlertContext);

    const [categories, setCategories] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [fullDocuments, setFullDocuments] = useState({});
    const [loading, setLoading] = useState({});

    const groupedDocuments = useMemo(() => {
        return groupBy(documents.map(d => fullDocuments[d.id]).filter(d => d), "transactionName");
    }, [documents, fullDocuments]);

    useEffect(() => {
        TermCategory.indexIncludeTerms()
            .then(response => setCategories(response.data))
            .catch(error => handleErrorAlert(error, "Can't get Term Categories and Terms"));
        Transaction.indexIncludeDocuments()
            .then(response => setTransactions(response.data))
            .catch(error => handleErrorAlert(error, "Can't get Transactions and Transaction Documents"));
    }, [handleErrorAlert]);
    // Only load the full document once and don't load while it's loading
    useEffect(() => {
        documents.filter(d => !loading[d.id] && !fullDocuments[d.id]).forEach(d => {
            setLoading(prev => ({...prev, [d.id]: true}));
            TransactionDocument.singleIncludeValues(d.id)
                .then(response => setFullDocuments(prev => ({
                    ...prev, [d.id]: {...response.data, transactionName: d.transactionName}
                }))).catch(error => handleErrorAlert(error, "Can't get Transaction Document"))
                .finally(() => setLoading(prev => ({...prev, [d.id]: false})));
        });
    }, [handleErrorAlert, documents, loading, fullDocuments]);

    return <Grid style={{width: "95%", margin: "0 auto"}}>
        <Grid.Row>
            <Grid.Column>
                <DocumentSelectorSegment transactions={transactions} setDocuments={setDocuments}/>
            </Grid.Column>
        </Grid.Row>

        {size(groupedDocuments) > 0 && categories.map(c => <Grid.Row key={c.id}>
            <Grid.Column>
                <Segment>
                    <ViewDocumentValuesTable category={c} documents={groupedDocuments}/>
                </Segment>
            </Grid.Column>
        </Grid.Row>)}
    </Grid>
}

const DocumentSelectorSegment = ({transactions, setDocuments}) => {
    const [transactionIds, setTransactionIds] = useState([]);

    const tOptions = useMemo(() => transactions.map(t => ({key: t.id, text: t.name, value: t.id})), [transactions]);

    // Keep order of selection, might be useful later
    const handleSelect = useCallback((transaction, documents) => {
        const docIds = documents.map(d => d.id);
        setDocuments(prev => {
            const prevIds = prev.map(d => d.id);
            return [...prev.filter(d => d.transactionId !== transaction.id || docIds.includes(d.id)),
                ...documents.filter(d => !prevIds.includes(d.id))];
        });
    }, [setDocuments]);

    // Remove transaction documents if the transaction is removed from the active transactions
    useEffect(() => {
        setDocuments(prev => prev.filter(d => transactionIds.includes(d.transactionId)));
    }, [transactionIds, setDocuments]);

    return <Segment>
        <Header as="h3" content="View Documents"/>

        <Form>
            <Form.Group widths="equal">
                <Form.Dropdown label="Transactions" multiple search selection options={tOptions} deburr clearable
                               onChange={(e, {value}) => setTransactionIds(value)} value={transactionIds}/>

                {transactionIds.map(tId => find(transactions, ["id", tId])).map(t =>
                    <DocumentDropdown key={t.id} transaction={t} handleSelect={handleSelect}/>)}
            </Form.Group>
        </Form>
    </Segment>
}

const DocumentDropdown = ({transaction, handleSelect}) => {
    const [documentsIds, setDocumentsIds] = useState([]);

    const dOptions = useMemo(() => {
        return transaction.documents.map(d => ({key: d.id, text: d.name, value: d.id}));
    }, [transaction]);
    const documents = useMemo(() => {
        return documentsIds.map(dId => find(transaction.documents, ["id", dId]))
            .map(d => ({...d, transactionName: transaction.name}));
    }, [documentsIds, transaction]);

    useEffect(() => handleSelect(transaction, documents), [handleSelect, documents, transaction]);

    return <Form.Dropdown label={transaction.name} multiple search selection options={dOptions} deburr clearable
                          onChange={(e, {value}) => setDocumentsIds(value)} value={documentsIds}/>
}