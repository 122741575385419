import {Header, Menu, Segment} from "semantic-ui-react";
import {DebouncedSearch} from "components/Search/DebouncedSearch";
import {useEffect, useState} from "react";

export const FormContainer = ({data, header, form, searchAttr, menuKey, menuName, activeKey, handleClick}) => {
    const [filtered, setFiltered] = useState(data);

    useEffect(() => setFiltered(data), [data]);

    return <>
        <Segment>
            <Header as="h3" content={header}/>

            {form}
        </Segment>

        <Menu fluid borderless attached="top">
            <Menu.Menu position="right">
                <Menu.Item>
                    <DebouncedSearch data={data} setFilteredData={setFiltered} attributes={searchAttr} duration={500}/>
                </Menu.Item>
            </Menu.Menu>
        </Menu>

        <Menu fluid attached="bottom" vertical>
            {filtered.map(f => <Menu.Item key={f[menuKey]} name={f[menuName]} content={f[menuName]}
                                          active={activeKey === f[menuKey]} onClick={() => handleClick(f)}/>)}
            {filtered.length === 0 && <Menu.Item content="No results"/>}
        </Menu>
    </>;
}