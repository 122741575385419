import React, {useCallback, useState} from "react";
import {Container, Dropdown, Icon, Image, Menu} from "semantic-ui-react";
import {SignOutDropdownItem} from "components/DropdownItem/SignOutDropdownItem";
import {useMsal} from "@azure/msal-react";
import {Link} from "react-router-dom";

const UserTrigger = ({username}) =>
    <span>
        <Icon name="user" size="large" circular/> {username}
    </span>

export const MainContainer = ({children}) => {
    const [activeItem, setActiveItem] = useState(null);

    const handleItemClick = useCallback((e, {name}) => setActiveItem(name),[]);

    const {accounts} = useMsal();
    const username = accounts.length ? accounts[0]["name"] || accounts[0]["username"] : "Unknown user";

    return (
        <>
            <Menu stackable borderless>
                <Menu.Item content={<Image src="surmount-ventures-logo-black-rgb.svg" size="small"/>}/>

                <Menu.Item as={Link} name="home" to="/" active={activeItem === "home"} onClick={handleItemClick}/>

                <Menu.Item as={Link} name="configuration" to="/configuration"
                           active={activeItem === "configuration"} onClick={handleItemClick}/>

                <Menu.Item as={Link} name="edit data" to="/edit_data"
                           active={activeItem === "edit data"} onClick={handleItemClick}/>

                <Menu.Menu position="right">
                    <Dropdown item floating trigger={<UserTrigger username={username}/>} icon="angle down">
                        <Dropdown.Menu>
                            <SignOutDropdownItem/>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Menu>
            </Menu>

            <Container fluid>
                {children}
            </Container>
        </>
    );
}