import React, {useCallback, useContext, useEffect, useState} from "react";
import {AlertContext} from "contexts/AlertContext";
import {Form} from "semantic-ui-react";
import {DocumentValue} from "services/LegalApi/DocumentValue";

const isSame = (value, valueFormData) => {
    const newValue = Object.fromEntries(valueFormData);

    return value.default === newValue.default &&
        value.arranged === newValue.arranged &&
        value.description === newValue.description;
}

export const DocumentValueForm = ({onSubmit, value}) => {
    const {handleErrorAlert} = useContext((AlertContext));

    const [state, setState] = useState(value);

    const handleChange = useCallback((e, {name, value}) => {
        setState(prevState => ({...prevState, [name]: value}));
    }, []);
    const handleSubmit = useCallback(({target}) => {
        const formData = new FormData(target);
        formData.set("documentId", value.documentId);
        formData.set("termId", value.termId);

        if (value.id) {
            if (!isSame(value, formData)) DocumentValue.update(value.id, formData)
                .then(_ => onSubmit())
                .catch(error => handleErrorAlert(error, "Can't update"));
        } else DocumentValue.create(formData)
            .then(_ => onSubmit())
            .catch(error => handleErrorAlert(error, "Can't add"));
    }, [value, handleErrorAlert, onSubmit]);

    useEffect(() => setState(value), [value]);

    return <Form onSubmit={handleSubmit}>
        <Form.TextArea label="Default" name="default" placeholder="N/A" value={state.default} onChange={handleChange}/>

        <Form.TextArea label="Arranged" name="arranged" value={state.arranged} onChange={handleChange}/>

        <Form.TextArea label="Description" name="description" value={state.description} onChange={handleChange}/>

        <Form.Button primary content="Update"/>
    </Form>
}