import React, {useMemo} from "react";
import {Modal, Popup, Table} from "semantic-ui-react";
import {find, keys, values} from "lodash";

export const ViewDocumentValuesTable = ({category, documents}) => {
    const docs = useMemo(() => values(documents).flatMap(d => d), [documents]);

    return <Table fixed definition celled structured compact>
        <Table.Header fullWidth>
            <Table.Row>
                <Table.HeaderCell rowSpan="2" children={category.name}/>

                {keys(documents).map(name =>
                    <Table.HeaderCell key={`${category.id}-${name}`} colSpan={documents[name].length} content={name}/>)}
            </Table.Row>
            <Table.Row>
                {docs.map(d => <Table.HeaderCell key={`${category.id}-${d.id}`} content={d.name}/>)}
            </Table.Row>
        </Table.Header>

        <Table.Body>
            {category.terms.map(t =>
                <Table.Row key={t.id}>
                    <Table.Cell content={t.name}/>

                    {docs.map(d => <DocumentValuesCell key={`${t.id}-${d.id}`} term={t} document={d}/>)}
                </Table.Row>)}
        </Table.Body>
    </Table>
}

const DocumentValuesCell = ({term, document}) => {
    const title = `[${document.transactionName}] ${document.name} -> ${term.name}`;
    const value = useMemo(() => find(document.values, ["termId", term.id]), [term, document]);
    let cell = <Table.Cell style={{whiteSpace: "pre-line"}}
                           positive={!!value?.arranged}
                           content={value?.arranged || value?.default || "N/A"}
                           disabled={!value?.arranged && !value?.default}
                           selectable={value?.description}/>;
    cell = value?.arranged && value?.default ?
        <DefaultPopup trigger={cell} title={title} content={value.default}/> : cell;

    return value?.description ? <DescriptionModal trigger={cell} title={title} content={value.description}/> : cell;
}

const DescriptionModal = ({trigger, title, content}) => {
    return <Modal dimmer="blurring" trigger={trigger} header={`${title} - Description`} content={content}
                  style={{whiteSpace: "pre-line"}}/>
}

const DefaultPopup = ({trigger, title, content}) => {
    return <Popup trigger={trigger} header={`${title} - Default`} content={content} style={{whiteSpace: "pre-line"}}
                  mouseEnterDelay={500} hoverable on="hover" wide/>
}