import React from "react";
import {Button, Icon, Image} from "semantic-ui-react";
import {loginRequest} from "authConfig";
import {useMsal} from "@azure/msal-react";

function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => console.error(e));
}

export const SignInButton = () => {
    const {instance} = useMsal();

    return (
        <Button primary fluid animated="fade" onClick={() => handleLogin(instance)}>
            <Button.Content visible>
                <Image
                    src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"
                    spaced
                    style={{height: "16px", width: "16px"}}
                />
                Continue
            </Button.Content>
            <Button.Content hidden>
                <Icon name="arrow right"/>
            </Button.Content>
        </Button>
    );
}
