import React, {useCallback, useContext, useEffect, useState} from "react";
import {AlertContext} from "contexts/AlertContext";
import {Transaction} from "services/LegalApi/Transaction";
import {Form} from "semantic-ui-react";
import {ButtonsWithDeleteConfirm} from "components/Form/ButtonsWithDeleteConfirm";

const isSame = (transaction, transactionFormData) => {
    const newTransaction = Object.fromEntries(transactionFormData);

    return transaction.name === newTransaction.name;
}

export const TransactionForm = ({onSubmit, transaction}) => {
    const {handleErrorAlert} = useContext(AlertContext);

    const [{name}, setState] = useState({name: ""});

    const handleChange = useCallback((e, {name, value}) => {
        setState(prevState => ({...prevState, [name]: value}));
    }, []);
    const handleSubmit = useCallback(({target}) => {
        const formData = new FormData(target);

        if (transaction) {
            if (!isSame(transaction, formData)) Transaction.update(transaction.id, formData)
                .then(_ => onSubmit())
                .catch(error => handleErrorAlert(error, "Can't update"));
        } else Transaction.create(formData)
            .then(_ => onSubmit())
            .catch(error => handleErrorAlert(error, "Can't add"));
    }, [transaction, handleErrorAlert, onSubmit]);
    const handleDelete = useCallback(() => {
        Transaction.remove(transaction.id)
            .then(_ => onSubmit())
            .catch(error => handleErrorAlert(error, "Can't delete"));
    }, [transaction, handleErrorAlert, onSubmit]);

    useEffect(() => setState({name: transaction?.name || ""}), [transaction]);

    return <Form onSubmit={handleSubmit}>
        <Form.Input required label="Name" name="name" value={name} onChange={handleChange}/>

        <ButtonsWithDeleteConfirm isAdd={transaction == null} onDelete={handleDelete} header="This can't be undone"
                                  content="Related documents and their values will be deleted. Are you sure?"/>
    </Form>;
}