import React, {useCallback} from "react";
import {Table} from "semantic-ui-react";
import {find} from "lodash";

export const EditDocumentValuesTable = ({category, document, handleRowClick}) => {
    const getValue = useCallback((term) => {
        const value = find(document.values, ["termId", term.id]);
        return value ? value : {documentId: document.id, termId: term.id, default: "", arranged: "", description: ""};
    }, [document]);

    return <Table definition celled selectable compact singleLine fixed>
        <Table.Header fullWidth>
            <Table.Row>
                <Table.HeaderCell children={category.name}/>
                <Table.HeaderCell children="Default"/>
                <Table.HeaderCell children="Arranged"/>
                <Table.HeaderCell children="Description"/>
            </Table.Row>
        </Table.Header>

        <Table.Body>
            {category.terms.map(t => {
                const value = getValue(t);

                return <Table.Row key={t.id} onClick={() => handleRowClick(t, value)}>
                    <Table.Cell content={t.name}/>
                    <Table.Cell content={value.default}/>
                    <Table.Cell content={value.arranged}/>
                    <Table.Cell content={value.description}/>
                </Table.Row>;
            })}
        </Table.Body>
    </Table>
}