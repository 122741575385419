import React from "react";
import {Route, Routes} from "react-router-dom";
import {MainContainer} from "containers/MainContainer";
import {HomePage} from "pages/HomePage";
import {ConfigurationPage} from "pages/ConfigurationPage";
import {EditDataPage} from "pages/EditDataPage";
import {Container, Grid} from "semantic-ui-react";
import {AlertContainer} from "containers/AlertContainer";

export const AuthenticatedContainer = () => {
    return (
        <MainContainer>
            <Container>
                <Grid>
                    <Grid.Row>
                        <Grid.Column children={<AlertContainer/>}/>
                    </Grid.Row>
                </Grid>
            </Container>

            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="configuration" element={<ConfigurationPage/>}/>
                <Route path="edit_data" element={<EditDataPage/>}/>
            </Routes>
        </MainContainer>
    );
}